import { routesApp } from '@/constans/routes';
import { HeaderMenu } from '../header-menu';
import { UserInfoChat } from '../user-info-chat';
import { Button } from '@/ui-kit';
import { VerticalDotsIcon } from '@/assets/icons';
import styles from './header-chat.module.scss';
import { useState } from 'react';
import { ModalSettingsUserChat } from '../modal-settings-user-chat';
import { useParams } from 'react-router';

interface Props {
    typeColor?: 'white' | 'gray';
}

export const HeaderChat: React.FC<Props> = ({ typeColor = 'white' }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { id } = useParams();
    const profileId = Number.isNaN(Number(id)) ? '' : Number(id);
    return (
        <>
            <HeaderMenu
                backLink={routesApp.messages}
                fixed
            >
                <UserInfoChat />
                <Button
                    className={styles.menuDropdown}
                    onClick={() => setIsOpenModal(true)}
                >
                    <VerticalDotsIcon
                        width={24}
                        height={24}
                    />
                </Button>
            </HeaderMenu>
            <ModalSettingsUserChat
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                profileId={profileId}
            />
        </>
    );
};
