import { Chat } from '@/types/chat';

export const chatList: Chat[] = [
    {
        id: 1,
        name: 'Светлана',
        age: 29,
        // avatar: 'https://loremflickr.com/200/200?random=1',
        lastMessage: 'Привет, как ты?',
        time: '17:45',
        count: 2,
        status: 'online',
    },
    {
        id: 2,
        name: 'Алексей',
        age: 34,
        avatar: 'https://loremflickr.com/200/200?random=2',
        lastMessage: 'Завтра встретимся?',
        time: '16:30',
        count: 1,
        status: 'offline',
    },
    {
        id: 3,
        name: 'Екатерина',
        age: 27,
        avatar: 'https://loremflickr.com/200/200?random=3',
        lastMessage: 'Отправила документы.',
        time: '14:10',
        count: 0,
        status: 'online',
    },
    {
        id: 4,
        name: 'Максим',
        age: 31,
        avatar: 'https://loremflickr.com/200/200?random=4',
        lastMessage: 'Позвони, как освободишься.',
        time: '13:20',
        count: 3,
        status: 'offline',
    },
    {
        id: 5,
        name: 'Анастасия',
        age: 25,
        avatar: 'https://loremflickr.com/200/200?random=5',
        lastMessage: 'Спасибо!',
        time: '12:05',
        count: 0,
        status: 'online',
    },
    {
        id: 6,
        name: 'Иван',
        age: 28,
        avatar: 'https://loremflickr.com/200/200?random=6',
        lastMessage: 'Когда будешь дома?',
        time: '11:50',
        count: 5,
        status: 'online',
    },
    {
        id: 7,
        name: 'Ольга',
        age: 32,
        avatar: 'https://loremflickr.com/200/200?random=7',
        lastMessage: 'Не забудь про встречу!',
        time: '10:40',
        count: 0,
        status: 'offline',
    },
    {
        id: 8,
        name: 'Дмитрий',
        age: 30,
        avatar: 'https://loremflickr.com/200/200?random=8',
        lastMessage: 'До завтра!',
        time: '09:35',
        count: 2,
        status: 'online',
    },
    {
        id: 9,
        name: 'Мария',
        age: 26,
        avatar: 'https://loremflickr.com/200/200?random=9',
        lastMessage: 'Всё готово!',
        time: '08:20',
        count: 0,
        status: 'offline',
    },
    {
        id: 10,
        name: 'Владимир',
        age: 35,
        avatar: 'https://loremflickr.com/200/200?random=10',
        lastMessage: 'Жду тебя у входа.',
        time: '07:15',
        count: 4,
        status: 'online',
    },
];
