import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetProfilesAll = () => {
    return useQuery({
        queryKey: ['profiles'],
        queryFn: () => profilesApi.getAll(),
    });
};
