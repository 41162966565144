import { Avatar, Typography } from '@/ui-kit';
import styles from './message-chat.module.scss';
import { Chat } from '@/types/chat';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { routesApp } from '@/constans/routes';
interface Props {
    chat: Chat;
}
export const MessageChat: React.FC<Props> = ({ chat }) => {
    const isNewMessage = !!chat.count;

    return (
        <div className={styles.messageChat}>
            <div className={styles.avatar}>
                <Avatar
                    size={61}
                    src={chat.avatar}
                    alt={chat.name}
                />
                {chat.status === 'online' && <span className={styles.status}></span>}
            </div>
            <div className={styles.wrapInfo}>
                <Link
                    to={generatePath(routesApp.chat, { id: String(chat.id) })}
                    className={styles.link}
                >
                    <div className={styles.info}>
                        <Typography
                            typeColor="brown"
                            className={styles.name}
                        >
                            {chat.name}, {chat.age}
                        </Typography>
                        <Typography
                            typeColor="gray"
                            className={styles.time}
                        >
                            {chat.time}
                        </Typography>
                    </div>
                    <div className={styles.messageInfo}>
                        <Typography
                            className={styles.lastMessage}
                            typeColor={isNewMessage ? 'orange' : 'gray-light'}
                        >
                            {chat.lastMessage}
                        </Typography>
                        {isNewMessage && (
                            <div className={styles.countMessage}>
                                <Typography
                                    variant="span"
                                    typeColor="white"
                                    className={styles.count}
                                >
                                    {chat.count}
                                </Typography>
                            </div>
                        )}
                    </div>
                </Link>
            </div>
        </div>
    );
};
