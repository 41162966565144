import { DislikeIcon, LikeFullIcon } from '@/assets/icons';
import styles from './buttons-like-and-dislike.module.scss';
import React from 'react';
import clsx from 'clsx';
import { useSwipedChange } from '@/store/useSwipedChange';
interface Props {
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
    visible?: boolean;
}
export const ButtonsLikeAndDislike: React.FC<Props> = ({ onSwipeLeft, onSwipeRight, visible }) => {
    const dir = useSwipedChange((state) => state.dir);
    return (
        <div className={styles.wrapper}>
            <button
                onClick={onSwipeLeft}
                className={clsx(
                    styles.button,
                    styles.left,
                    visible && styles.visible,
                    dir === 'left' && !!dir && styles.active,
                )}
            >
                <DislikeIcon
                    width={78}
                    height={78}
                />
            </button>
            <button
                onClick={onSwipeRight}
                className={clsx(
                    styles.button,
                    styles.right,
                    visible && styles.visible,
                    dir === 'right' && !!dir && styles.active,
                )}
            >
                <LikeFullIcon
                    width={78}
                    height={78}
                />
            </button>
        </div>
    );
};
