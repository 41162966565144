import { Link } from 'react-router-dom';
import styles from './settings-item.module.scss';
import { ReactNode } from 'react';
import { Typography } from '@/ui-kit';

interface Props {
    icon?: ReactNode;
    title?: string;
    path?: string;
}

export const SettingsItem: React.FC<Props> = ({ icon, title, path }) => {
    return (
        <Link
            to={path ?? '#'}
            className={styles.settingsItem}
        >
            {icon && icon}
            <Typography
                className={styles.text}
                typeColor="brown"
            >
                {title}
            </Typography>
        </Link>
    );
};
