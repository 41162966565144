import axios from 'axios';
import { jwtService } from './jwt.service';

jwtService.initToken();

const ApiClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    withCredentials: true,
    headers: {
        Authorization: 'Bearer ' + jwtService.getToken(),
    },
});

export { ApiClient };
