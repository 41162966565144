import React, { useEffect } from 'react';
import { Button, Container, Typography } from '@/ui-kit';
import styles from './intro.module.scss';
import { routesApp } from '@/constans/routes';
import { generatePath, useNavigate } from 'react-router';
import { LogoTravelask } from '@/assets/icons';
import { useAuth } from '@/store/useAuth';

export const Intro: React.FC = () => {
    const { user, emptyFields } = useAuth((state) => state);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (emptyFields()) {
    //         return;
    //     } else if (!emptyFields() && user?.state !== 'complete') {
    //         navigate(generatePath(routesApp.registration, { stepNumer: '1' }));
    //         return;
    //     } else {
    //         navigate(generatePath(routesApp.browse));
    //     }
    // }, [emptyFields, navigate, user]);

    return (
        <section className={styles.intro}>
            <Container className={styles.container}>
                <div className={styles.intro__deckor}></div>
                <Typography
                    typeColor="white"
                    variant="h1"
                    center
                >
                    Знакомства <br /> для любителей путешествовать
                </Typography>
                <div className={styles.logoTravelask}>
                    <Typography
                        fontWeight="bold"
                        typeColor="black"
                        className={styles.labelLogo}
                    >
                        by
                    </Typography>{' '}
                    <LogoTravelask
                        width={142}
                        height={28}
                    />
                </div>
                <Typography
                    typeColor="white"
                    variant="p"
                    className={styles.intro__text}
                    center
                >
                    Приложение для знакомств, которое объединяет любителей путешествий для совместных приключений
                    и&nbsp;обмена опытом.
                </Typography>
                <Button
                    isLink
                    to={generatePath(routesApp.registration, { stepNumer: '1' })}
                    variant="stretched"
                >
                    Регистрация
                </Button>
                <Typography
                    center
                    className={styles.introFootnote}
                >
                    Принимаю условия регистрации пользователей.
                </Typography>
            </Container>
        </section>
    );
};
