export const couplesItems = [
    {
        id: 1,
        name: 'Светлана',
        avatar: 'https://loremflickr.com/200/200?random=1',
        isViewed: true,
    },
    {
        id: 2,
        name: 'Алексей',
        avatar: 'https://loremflickr.com/200/200?random=2',
        isViewed: true,
    },
    {
        id: 3,
        name: 'Екатерина',
        avatar: 'https://loremflickr.com/200/200?random=3',
        isViewed: false,
    },
    {
        id: 4,
        name: 'Максим',
        avatar: 'https://loremflickr.com/200/200?random=4',
        isViewed: true,
    },
    {
        id: 5,
        name: 'Анастасия',
        avatar: 'https://loremflickr.com/200/200?random=5',
        isViewed: true,
    },
    {
        id: 6,
        name: 'Иван',
        avatar: 'https://loremflickr.com/200/200?random=6',
        isViewed: false,
    },
    {
        id: 7,
        name: 'Ольга',
        avatar: 'https://loremflickr.com/200/200?random=7',
        isViewed: false,
    },
    {
        id: 8,
        name: 'Дмитрий',
        avatar: 'https://loremflickr.com/200/200?random=8',
        isViewed: true,
    },
    {
        id: 9,
        name: 'Мария',
        avatar: 'https://loremflickr.com/200/200?random=9',
        isViewed: false,
    },
    {
        id: 10,
        name: 'Владимир',
        avatar: 'https://loremflickr.com/200/200?random=10',
        isViewed: true,
    },
];
