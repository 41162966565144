import React, { useRef, useState } from 'react';
import { PlusIcon } from '@/assets/icons';
import styles from './uploader.module.scss';
import clsx from 'clsx';

interface Props {
    onChange?: (file: File | null) => void;
    photoUrl?: string | null;
    onDeletePhoto?: () => void;
}

export const Uploader: React.FC<Props> = ({ onChange, photoUrl, onDeletePhoto }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(photoUrl ?? null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleUploadClick = () => {
        if (!imageSrc) {
            fileInputRef.current?.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const validFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
            const maxSizeInMB = 2;

            if (!validFormats.includes(file.type)) {
                alert('Неверный формат файла. Загрузите изображение в формате PNG, JPEG, JPG или WEBP.');
                return;
            }

            if (file.size > maxSizeInMB * 1024 * 1024) {
                alert('Размер файла превышает 2 МБ. Пожалуйста, загрузите файл меньшего размера.');
                return;
            }
            const reader = new FileReader();

            reader.onloadend = () => {
                setImageSrc(reader.result as string);
            };

            reader.readAsDataURL(file);

            onChange?.(file);
        }
    };
    const handleRemoveImage = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setImageSrc(null);
        onChange?.(null);
        onDeletePhoto?.();
    };

    return (
        <div
            className={styles.uploader}
            onClick={handleUploadClick}
        >
            <input
                type="file"
                hidden
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
            />
            {imageSrc && (
                <img
                    src={imageSrc}
                    alt="Uploaded"
                    className={styles.uploadedImage}
                />
            )}

            <div
                className={clsx(styles.uploaderIcon, imageSrc && styles.active)}
                onClick={imageSrc ? handleRemoveImage : undefined}
            >
                <PlusIcon
                    width={34}
                    height={34}
                />
            </div>
        </div>
    );
};
