import React, { PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@/store/useAuth';

interface Props {
    loginPath: string;
}

export const PrivateRoutes: React.FC<PropsWithChildren<Props>> = ({ loginPath }) => {
    const location = useLocation();

    const emptyFields = useAuth((state) => state.emptyFields);

    if (emptyFields()) {
        return (
            <Navigate
                replace
                to={loginPath}
                state={{ from: location }}
            />
        );
    }

    return <Outlet />;
};
