import { InfoIcon, QuestionIcon, SettingsDetilsIcon, SettingsIcon, WorldIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';

interface RouteSettings {
    title: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    path: string;
    value: string;
}

export const dataSettingsRoutes: RouteSettings[] = [
    {
        title: 'Personal Detail',
        icon: SettingsDetilsIcon,
        path: routesApp.settings,
        value: 'Personal Detail',
    },
    {
        title: 'Settings',
        icon: SettingsIcon,
        path: routesApp.settings,
        value: 'Settings',
    },
    {
        title: 'Terma & Conditions',
        icon: WorldIcon,
        path: routesApp.settings,
        value: 'Terma & Conditions',
    },
    {
        title: 'Privacy Policy',
        icon: InfoIcon,
        path: routesApp.settings,
        value: 'Privacy Policy',
    },
    {
        title: 'About Us',
        icon: QuestionIcon,
        path: routesApp.settings,
        value: 'About Us',
    },
];
