import React, { forwardRef, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './input.module.scss';
import { Typography } from '../typography';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: any;
    success?: boolean;
}

export const Input = forwardRef<HTMLInputElement, Props>(
    ({ label, error, success, className, required, disabled, ...rest }, ref) => {
        return (
            <div className={styles.container}>
                {label && (
                    <Typography
                        fontWeight="semibold"
                        variant="label"
                        className={clsx(required && styles.required, styles.label, error && styles.error)}
                    >
                        {label} {}
                    </Typography>
                )}
                <input
                    ref={ref}
                    className={clsx(
                        styles.input,
                        {
                            [styles.error]: error,
                            [styles.success]: success && !error,
                            [styles.disabled]: disabled,
                        },
                        className,
                    )}
                    disabled={disabled}
                    {...rest}
                />
                {/* <FieldError message={error} /> */}
            </div>
        );
    },
);
Input.displayName = 'Input';
