import { Container, Typography } from '@/ui-kit';
import styles from './error-message.module.scss';
export const ErrorMessage = ({ message }: { message: string }) => {
    return (
        <Container className={styles.container}>
            <Typography
                variant="h4"
                typeColor="brown"
                center
            >
                {message}
            </Typography>
        </Container>
    );
};
