import React, { PropsWithChildren, useEffect, useState } from 'react';
import styles from './modal.module.scss';
import { Button } from '../button';
import { CloseIcon } from '@/assets/icons';
import clsx from 'clsx';
import { Typography } from '../typography';

export interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    title?: string;
}

const ANIMATION_DELAY = 300;

export const Modal: React.FC<PropsWithChildren<Props>> = ({ isOpen, onClose, children, title }) => {
    const [isClosing, setIsClosing] = useState(false);
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        if (isOpen) {
            setVisible(true);
        } else {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setVisible(false);
            }, ANIMATION_DELAY);
        }
    }, [isOpen]);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={clsx(styles.overlay, isClosing && styles.closing)}
            onClick={onClose}
        >
            <div
                className={clsx(styles.modal, isClosing && styles.closing)}
                onClick={(e) => e.stopPropagation()} // Останавливает закрытие модалки при клике внутри нее
            >
                {title && (
                    <Typography
                        variant="p"
                        fontWeight="bold"
                        className={styles.title}
                        center
                    >
                        {title}
                    </Typography>
                )}
                <Button
                    className={styles.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};
