import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { authApi } from '@/api';
import { Photo, type Profile } from '@/types/profile';

interface AuthStore {
    user: Profile | null;
    login: (authData: any) => Promise<any | unknown>;
    logout: () => Promise<void>;
    setProfile: (user: any) => void;
    emptyFields: () => boolean;
    getUser: () => Profile | null;
}

export const useAuth = create<AuthStore>()(
    devtools(
        immer((set, get) => ({
            user: null,
            telegramWebAppInitData: null,
            login: async (authData: any) => {
                return null;
            },
            setProfile: (user: Profile) => {
                set({ user });
            },
            logout: async () => {
                await authApi.logout();
                set({ user: null });
            },
            emptyFields: () => {
                const getUser = get().user;
                if (!getUser) return false;
                return (
                    !getUser?.dob &&
                    !getUser?.travel_plans &&
                    !getUser?.photos.length &&
                    !getUser?.gender &&
                    !getUser?.public_name &&
                    !getUser?.about_me &&
                    !getUser?.looks_for &&
                    !getUser?.min_age &&
                    !getUser?.max_age
                );
            },
            getUser: () => get().user,
        })),
    ),
);
