import { SettingsItem } from '../settings-item';
import { dataSettingsRoutes } from './data-settings-routes';
import styles from './settings-list.module.scss';

export const SettingsList = () => {
    return (
        <div>
            {dataSettingsRoutes.map((item) => {
                const IconComponent = item.icon;
                return (
                    <SettingsItem
                        key={item.value}
                        icon={
                            <IconComponent
                                width={20}
                                height={20}
                            />
                        }
                        title={item.title}
                    />
                );
            })}
        </div>
    );
};
