import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './message-chat.module.scss';

export const SkeletonMessageChat: React.FC = () => {
    return (
        <div className={styles.messageChat}>
            {/* Скелетон для аватара */}
            <div className={styles.avatar}>
                <Skeleton
                    circle={true}
                    height={61}
                    width={61}
                />
            </div>
            <div className={styles.wrapInfo}>
                <div className={styles.info}>
                    {/* Скелетон для имени и возраста */}
                    <Skeleton
                        width={120}
                        height={20}
                    />
                    {/* Скелетон для времени */}
                    <Skeleton
                        width={60}
                        height={15}
                    />
                </div>
                <div className={styles.messageInfo}>
                    {/* Скелетон для последнего сообщения */}
                    <Skeleton
                        width={170}
                        height={20}
                    />
                    {/* Скелетон для количества новых сообщений */}
                    <Skeleton
                        width={30}
                        height={30}
                    />
                </div>
            </div>
        </div>
    );
};
