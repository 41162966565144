import { Modal } from '@/ui-kit';
import { ToolItem } from './components';
import { SettingsData, settingsData } from './settings-data';
import { useBlockedProfileById } from '@/hooks/use-blocked-profile-by-id';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    profileId: number | string;
}

export const ModalSettingsUserChat: React.FC<Props> = ({ isOpen, onClose, profileId }) => {
    const { mutateAsync: blockedProfileById } = useBlockedProfileById();

    const handleChangeSettingsProfile = async (settings: SettingsData['action']) => {
        try {
            console.log(settings);

            switch (settings) {
                case 'block':
                    // await blockedProfileById(profileId);
                    break;
                case 'complain':
                    break;
                case 'unvote':
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Инструменты безопасности"
        >
            {settingsData.map((item) => {
                const IconComponent = item.icon;
                return (
                    <ToolItem
                        key={item.title}
                        title={item.title}
                        text={item.type}
                        icon={<IconComponent />}
                        onClick={() => handleChangeSettingsProfile(item.action)}
                    />
                );
            })}
        </Modal>
    );
};
