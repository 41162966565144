import { PuffLoader } from 'react-spinners';
import styles from './spiner.module.scss';
export const Spiner = () => {
    return (
        <div className={styles.wrapper}>
            <PuffLoader
                size={100}
                color="#F26A1C"
            />
        </div>
    );
};
