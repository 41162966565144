import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './chat-viewport.module.scss';
import { Container, Typography } from '@/ui-kit';
import { FormMessage, ChatMessageItem, BadgeDay, TextIsPrinted, SkeletonChatViewport } from './components';
import { useParams } from 'react-router';
import { useActionCable, useChannel } from '@aersoftware/react-use-action-cable';
import { jwtService } from '@/services/jwt.service';
import { useGetMessagesById } from '@/hooks/use-get-messages';
import { MessageDto } from '@/types/message';
import { format } from 'date-fns';

import { ErrorMessage } from '../error-message';

interface Props {
    chatUrl?: string;
}

const CHANEL_NAME = 'ChatChannel';
const isDev = process.env.NODE_ENV === 'development';
const getPathFromWebSocketUrlIsDev = (url: string) => {
    if (isDev) {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    }
    return url;
};

export const ChatViewport: React.FC<Props> = ({ chatUrl = '' }) => {
    const srollBottomBlockRef = useRef<null | HTMLDivElement>(null);

    const [messages, setMessages] = useState<MessageDto[]>([]);

    const [isFirstRender, setIsFirstRender] = useState(false);

    const { id } = useParams();

    const { actionCable } = useActionCable(getPathFromWebSocketUrlIsDev(chatUrl));

    const profileId = Number.isNaN(Number(id)) ? '' : Number(id);

    const { data: dataMessages, isError, isLoading } = useGetMessagesById(profileId);

    useEffect(() => {
        if (isFirstRender) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
            });
        }
    }, [isFirstRender]);

    const { subscribe, unsubscribe, send } = useChannel(actionCable);

    useEffect(() => {
        subscribe(
            {
                channel: CHANEL_NAME,
                token: jwtService.getToken(),
                profile: id,
            },
            {
                received: (data: MessageDto) => {
                    setMessages((prevMessages) => [...prevMessages, data]);
                    if (data.is_mine) {
                        setTimeout(() => {
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                            });
                        }, 0);
                    }
                },
            },
        );
        return () => {
            unsubscribe();
        };
    }, [id, subscribe, unsubscribe]);

    // Группировка сообщений по дате
    const groupedMessages = useMemo(
        () =>
            messages.reduce<{ date: string; messages: MessageDto[] }[]>((acc, message) => {
                const messageDate = format(new Date(message.created_at), 'yyyy-MM-dd');

                const existingGroup = acc.find((group) => group.date === messageDate);

                if (existingGroup) {
                    existingGroup.messages.push(message);
                } else {
                    acc.push({
                        date: messageDate,
                        messages: [message],
                    });
                }

                return acc;
            }, []),
        [messages],
    );

    useEffect(() => {
        if (dataMessages?.data) {
            setMessages(dataMessages.data);
            setIsFirstRender(true);
        }
    }, [dataMessages, isFirstRender]);

    if (isError) {
        return <ErrorMessage message="Ошибка получения сообщений 🙄" />;
    }

    return (
        <Container className={styles.container}>
            <div className={styles.chatViewport}>
                {isLoading ? (
                    <SkeletonChatViewport />
                ) : messages.length > 0 ? (
                    groupedMessages?.map((group, index) => (
                        <div key={group.date}>
                            <BadgeDay textDate={format(new Date(group.date), 'MMMM dd, yyyy')} />
                            {group.messages.map((message) => {
                                return (
                                    <ChatMessageItem
                                        key={message.id}
                                        status="read"
                                        time={format(new Date(message.created_at), 'hh:mm a')}
                                        isMyMessage={message.is_mine}
                                        message={message.text}
                                    />
                                );
                            })}
                        </div>
                    ))
                ) : (
                    <Typography center>Список сообщений пуст</Typography>
                )}
                {/* <TextIsPrinted name="Светлана" /> */}
            </div>
            <FormMessage onSend={send} />
            <div ref={srollBottomBlockRef} />
        </Container>
    );
};
