import React from 'react';
import styles from './pagination.module.scss';
import clsx from 'clsx';
interface Props {
    total: number;
    activeIndex: number;
    isVisible?: boolean;
}
export const Pagination: React.FC<Props> = ({ total, activeIndex, isVisible = true }) => {
    return isVisible ? (
        <div className={styles.pagination}>
            <ul className={styles.paginationList}>
                {Array(total)
                    .fill(0)
                    .map((_, index) => (
                        <li
                            key={index}
                            className={clsx(styles.paginationItem, activeIndex === index && styles.active)}
                        >
                            {index}
                        </li>
                    ))}
            </ul>
        </div>
    ) : null;
};
