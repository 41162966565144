import Router from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';

const queryClient = new QueryClient();

export const App = () => {
    useEffect(() => {
        WebApp.ready();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Router />
        </QueryClientProvider>
    );
};
