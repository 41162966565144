import { useMemo, useState } from 'react';
import styles from './gallery-images.module.scss';
import { Navigation, Pagination } from './componenets';
import { useSwipedChange } from '@/store/useSwipedChange';
import { Photo } from '@/types/profile';
import clsx from 'clsx';
interface Props {
    photos: Photo[];
    isVisibleDetails?: boolean;
}
export const GalleryImages: React.FC<Props> = ({ photos, isVisibleDetails }) => {
    const { isSwiping } = useSwipedChange((state) => state);
    const [currentIndexGallery, setCurrentIndexGallery] = useState(0);
    const currentGalleryImage = useMemo(() => photos[currentIndexGallery], [currentIndexGallery, photos]);
    const handleChangeGallery = (idx: number, dir: 'left' | 'right') => {
        if (isSwiping) {
            return;
        }

        if (dir === 'left' && idx > 0) {
            setCurrentIndexGallery(idx - 1);
        }

        if (dir === 'right' && idx < photos.length - 1) {
            setCurrentIndexGallery(idx + 1);
        }
    };

    return (
        <div className={clsx(styles.wrapper, { [styles.visibleDetails]: isVisibleDetails })}>
            <Pagination
                total={photos.length}
                activeIndex={currentIndexGallery}
                isVisible={photos.length > 1}
            />
            <div className={styles.image}>
                <img
                    src={currentGalleryImage?.url}
                    alt={currentGalleryImage?.metadata.filename}
                />
            </div>
            <Navigation
                currentIndex={currentIndexGallery}
                onChange={handleChangeGallery}
            />
        </div>
    );
};
