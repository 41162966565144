import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Browse, Messages, Home, Registration, Chat, Liked, Settings } from '@/pages';
import { routesApp } from '@/constans/routes';
import { AuthProvider, PrivateRoutes, RegistrationLayout, PublicRoutes } from '@/components';
import { AppLayout } from '@/components/layouts/app-layout';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* <Route element={<PublicRoutes browsePath={routesApp.browse} />}> */}

            <Route
                path={routesApp.home}
                element={<Home />}
            />
            <Route element={<RegistrationLayout />}>
                <Route
                    path={routesApp.registration}
                    element={<Registration />}
                />
            </Route>
            {/* </Route> */}
            <Route element={<PrivateRoutes loginPath={routesApp.registration} />}>
                <Route element={<AppLayout />}>
                    <Route
                        path={routesApp.browse}
                        element={<Browse />}
                    />
                    <Route
                        path={routesApp.messages}
                        element={<Messages />}
                    />
                    <Route
                        path={routesApp.chat}
                        element={<Chat />}
                    />
                    <Route
                        path={routesApp.liked}
                        element={<Liked />}
                    />
                </Route>
                <Route
                    path={routesApp.settings}
                    element={<Settings />}
                />
            </Route>
        </>,
    ),
    { basename: '/app' },
);

const Router: React.FC = () => (
    <AuthProvider>
        <RouterProvider router={router} />
    </AuthProvider>
);

export default Router;
