import { Typography } from '@/ui-kit';
import styles from './goal-card.module.scss';
import clsx from 'clsx';
import { ReactNode } from 'react';
interface Props {
    text: string;
    icon: ReactNode;
    active?: boolean;
    onChange?: () => void;
}
export const GoalCard: React.FC<Props> = ({ text, icon, active, onChange }) => {
    return (
        <div
            onClick={onChange}
            className={clsx(styles.card, active && styles.active)}
        >
            {/* <div className={clsx(styles.cardIcon, styles[icon])} /> */}
            {icon}
            <Typography
                center
                className={styles.cardTitle}
                fontWeight="bold"
            >
                {text}
            </Typography>
        </div>
    );
};
