import React from 'react';
import styles from './avatar.module.scss';
import { UserAvatarIcon } from '@/assets/icons';
import clsx from 'clsx';
interface Props {
    size?: number;
    src?: string;
    alt?: string;
}
export const Avatar: React.FC<Props> = ({ size = 60, src, alt }) => {
    return (
        <div
            style={{ width: size, height: size }}
            className={clsx(styles.avatar, !src && styles.default)}
        >
            {src ? (
                <img
                    src={src}
                    alt={alt}
                />
            ) : (
                <UserAvatarIcon
                    width={size / 2}
                    height={size / 2}
                />
            )}
        </div>
    );
};
