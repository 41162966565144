import { ChatViewport, HeaderChat } from '@/components';
import { useGetSettings } from '@/hooks/use-get-settings';
import { Spiner } from '@/ui-kit';

export const Chat = () => {
    const { data, isLoading } = useGetSettings();
    return (
        <>
            <HeaderChat />
            {isLoading ? <Spiner /> : <ChatViewport chatUrl={data?.data.chat_url} />}
        </>
    );
};
