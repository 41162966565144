import React, { PropsWithChildren } from 'react';
import styles from './filed-block.module.scss';
import clsx from 'clsx';

interface Props {
    error?: boolean;
}
export const FieldBlock: React.FC<PropsWithChildren<Props>> = ({ children, error }) => {
    return <div className={clsx(styles.fieldBlock, error && styles.error)}>{children}</div>;
};
