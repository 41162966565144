import { FormRegistration, HeaderMenu } from '@/components';
import { StepLabel } from '@/components/form-registration';
import { routesApp } from '@/constans/routes';
import { useAuth } from '@/store/useAuth';
import { Spiner } from '@/ui-kit';
import { useState } from 'react';
import { generatePath, useParams } from 'react-router';

const generateBackUrlSteps = (stepNumer?: string) => {
    const step = Number(stepNumer);
    if (!Number.isNaN(step) && step <= 4 && step > 1) {
        return generatePath(routesApp.registration, { stepNumer: String(step - 1) });
    }
    return generatePath(routesApp.home);
};

export const Registration = () => {
    const [isLoadingOnce, setIsLoadingOnce] = useState(true);
    const userData = useAuth((state) => state.getUser());
    const { stepNumer } = useParams();
    return userData ? (
        <>
            <HeaderMenu
                typeColor="gray"
                backLink={generateBackUrlSteps(stepNumer)}
            >
                <StepLabel stepNumber={stepNumer as string} />
            </HeaderMenu>
            <FormRegistration
                step={stepNumer}
                isLoadingOncePage={isLoadingOnce}
                onChangeIsLoadingPage={setIsLoadingOnce}
            />
        </>
    ) : (
        <Spiner />
    );
};
