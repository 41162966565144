import { photoApi } from '@/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeletePhotoById = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: photoApi.deletePhotoById,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profile'] });
        },
        onError: (error) => {
            console.error('Error delete user photo:', error);
        },
    });

    return mutation;
};
