import { HeaderMenu, MessagesList, NewCouples } from '@/components';
import { routesApp } from '@/constans/routes';
import { chatList } from '@/mocks/chat-list';
import styles from './messages.module.scss';
import { useEffect, useState } from 'react';
export const Messages = () => {
    const [isLoadingMock, setIsLoadingMock] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingMock(false);
        }, 2000);
    }, []);
    return (
        <div className={styles.messages}>
            <HeaderMenu
                backLink={routesApp.browse}
                title="Сообщения"
                fixed
                isGray
            />
            <NewCouples isLoading={isLoadingMock} />
            <MessagesList
                isLoading={isLoadingMock}
                chatList={chatList}
            />
        </div>
    );
};
