import { useQuery } from '@tanstack/react-query';

import { profilesApi } from '@/api';

export const useGetMessagesById = (profileId: number | string) => {
    return useQuery({
        queryKey: ['messages', profileId],
        queryFn: () => profilesApi.getMessagesById(profileId),
    });
};
