import { BlockedIcon, DeleteIcon, FlagIcon } from '@/assets/icons';

export interface SettingsData {
    title: string;
    type: string;
    icon: React.FC;
    action: 'block' | 'complain' | 'unvote';
}

export const settingsData: SettingsData[] = [
    {
        title: 'УДАЛИТЬ ИЗ ПАР: ANIL',
        type: 'Больше не хочешь общаться? Удали пользователя из пар.',
        icon: BlockedIcon,
        action: 'unvote',
    },
    {
        title: 'ЗАБЛОКИРОВАТЬ ПОЛЬЗОВАТЕЛЯ ANIL',
        type: 'Вы больше не будете видеть друг друга.',
        icon: DeleteIcon,
        action: 'block',
    },
    {
        title: 'ПОЖАЛОВАТЬСЯ НА ANIL',
        type: 'Не волнуйся: пользователь об этом не узнает.',
        icon: FlagIcon,
        action: 'complain',
    },
];
