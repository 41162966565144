import React from 'react';
import styles from './badge-day.module.scss';
import { Typography } from '@/ui-kit';
interface Props {
    textDate?: string;
}
export const BadgeDay: React.FC<Props> = ({ textDate }) => {
    return textDate ? (
        <div className={styles.badgeDay}>
            <Typography
                className={styles.badgeText}
                typeColor="gray"
            >
                {textDate}
            </Typography>
        </div>
    ) : null;
};
