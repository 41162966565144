import { ApiClient } from '@/services/api.service';
import { type Profile } from '@/types/profile';
import { jwtService } from '@/services/jwt.service';

class AuthApi {
    async getProfile(): Promise<any> {
        const data = await ApiClient.get<any>(`/profile/`, {}).catch((error) => {
            const status = error.response ? error.response.status : null;
            // Пока проверка на 403 ошибку
            if (status === 403) {
                alert('Внутрення ошибка');
            }
        });
        return data;
    }

    async updatedProfile(updateUserDataDto: Partial<Profile>) {
        const data = await ApiClient.patch<any>(`/profile/`, {
            profile: updateUserDataDto,
        });
        return data;
    }

    async logout() {
        //await ApiClient.post('/auth/logout');
        jwtService.destroyToken();
    }
}

export const authApi = new AuthApi();
