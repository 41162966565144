import React, { PropsWithChildren, useLayoutEffect } from 'react';
import { useAuth } from '@/store/useAuth';
import { useGetProfile } from '@/hooks/use-get-profile';
import { Spiner } from '@/ui-kit';
import { ErrorMessage } from '../error-message';
// import WebApp from '@twa-dev/sdk';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { data, isLoading, isError } = useGetProfile();
    const { setProfile } = useAuth((state) => state);

    useLayoutEffect(() => {
        if (data) {
            setProfile(data?.data);
        }
    }, [data, setProfile]);

    if (isError) {
        return <ErrorMessage message={'Ошибка загрузки профиля'} />;
    }

    return isLoading ? <Spiner /> : <>{children}</>;
};
