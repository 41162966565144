import { ArrowTopIcon, LocationIcon } from '@/assets/icons';
import { Container, Tag, Typography } from '@/ui-kit';
import styles from './card-swipe-user.module.scss';

import TinderCard from 'react-tinder-card';
import { createRef, useMemo, useRef, useState } from 'react';
import { ButtonsLikeAndDislike } from '../buttons-like-and-dislike';
import { useSwipedChange } from '@/store/useSwipedChange';
import { GalleryImages } from '../gallery-images';
import { Profile } from '@/types/profile';
import { useVoteProfileById } from '@/hooks/use-vote-profile-by-id';
import { Gender } from '@/constans/gender';
import clsx from 'clsx';
import { differenceInYearsResult } from '@/utils/difference-in-yesrs-result';
const ANIMATION_SLEEP_DELAY = 300;
interface Props {
    users?: Profile[];
}

type Dir = 'left' | 'right' | null;

export const CardSwiperUser: React.FC<Props> = ({ users = [] }) => {
    const { mutateAsync: voteProfileById } = useVoteProfileById();
    const setDir = useSwipedChange((state) => state.setDir);
    const lastIndex = users.length - 1;
    const [currentSwipeSlide, setCurrentSwipeSlide] = useState<Profile>(users[lastIndex]);
    const [currentIndex, setCurrentIndex] = useState(users.length - 1);
    const [_, setLastDirection] = useState<Dir>(null);
    const currentIndexRef = useRef(currentIndex);
    const [isVisibleDetails, setIsVisibleDetails] = useState(false);

    const detailsRef = useRef<HTMLDivElement>(null);

    const childRefs = useMemo(
        () =>
            Array(users.length)
                .fill(0)
                .map(() => createRef<any>()),
        [users.length],
    );
    const canSwipe = currentIndex >= 0;
    const handleCurrentIndex = (val: number) => {
        setCurrentIndex(val);
        currentIndexRef.current = val;
        setCurrentSwipeSlide(users[val]);
        setDir(null);
    };

    const handleChangeSwipe = (direction: Dir, index: number) => {
        setLastDirection(direction);
        handleCurrentIndex(index - 1);
    };

    const handlerOutOfFrame = async (idx: number, dir: Dir) => {
        try {
            console.log(`Card ${idx} left the screen!`);
            setDir(null);
            await voteProfileById({
                profileId: users[idx].id,
                voteProfileDto: {
                    vote: dir === 'left' ? 'dislike' : 'like',
                },
            });
        } catch (e) {
            console.log(`error`, e);
        } finally {
            setIsVisibleDetails(false);
        }
    };
    const handelClickButton = async (dir: Dir) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(async () => {
            if (canSwipe && currentIndex < users.length) {
                await childRefs[currentIndex].current.swipe(dir);
            }
            if (isVisibleDetails) {
                setIsVisibleDetails(false);
            }
        }, ANIMATION_SLEEP_DELAY);
    };

    const hendleSrollDetails = () => {
        setIsVisibleDetails(true);
        if (detailsRef.current) {
            setTimeout(() => {
                const rect = detailsRef.current?.getBoundingClientRect();
                window.scrollTo({ top: rect?.top, behavior: 'smooth' });
            }, ANIMATION_SLEEP_DELAY);
        }
    };

    return (
        <div className={styles.container}>
            {users.map((user, index) => (
                <div
                    className={styles.tinderCardWrapper}
                    key={user.id}
                >
                    <TinderCard
                        ref={childRefs[index]}
                        preventSwipe={['up', 'down']}
                        className={styles.tinderCard}
                        flickOnSwipe={true}
                        swipeRequirementType="position"
                        swipeThreshold={100}
                        onSwipe={(dir) => {
                            handleChangeSwipe(dir as Dir, index);
                        }}
                        onCardLeftScreen={(dir) => handlerOutOfFrame(index, dir as Dir)}
                        onSwipeRequirementFulfilled={(dir) => {
                            console.log('swipe fulfilled');
                            setDir(dir as Dir);
                        }}
                        onSwipeRequirementUnfulfilled={() => {
                            console.log('swipe unfulfilled');

                            setDir(null);
                        }}
                    >
                        <div className={styles.wrapper}>
                            <div className={clsx(styles.card)}>
                                <GalleryImages photos={user.photos} />
                                <div className={clsx(styles.cardInfo, isVisibleDetails && styles.visibleDetails)}>
                                    <Typography
                                        typeColor="white"
                                        fontWeight="medium"
                                        variant="h3"
                                        className={styles.cardInfoText}
                                    >
                                        {user.public_name}, {differenceInYearsResult(user?.dob)}
                                    </Typography>
                                    <Typography
                                        typeColor="white"
                                        fontWeight="normal"
                                        variant="p"
                                        className={styles.descriptionIntro}
                                    >
                                        {user.about_me}
                                        {/* {user.public_name + ', ' + user.age} */}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </TinderCard>
                </div>
            ))}
            <ButtonsLikeAndDislike
                onSwipeLeft={() => handelClickButton('left')}
                onSwipeRight={() => handelClickButton('right')}
                visible={true}
            />

            <div className={clsx(styles.descriptionWrapper, isVisibleDetails && styles.visibleDetails)}>
                <Container>
                    <div ref={detailsRef} />
                    {currentSwipeSlide?.public_name && (
                        <Typography
                            typeColor="black"
                            fontWeight="medium"
                            variant="h3"
                            className={styles.cardInfoText}
                        >
                            {currentSwipeSlide?.public_name}, {differenceInYearsResult(currentSwipeSlide?.dob)}
                        </Typography>
                    )}
                    {currentSwipeSlide?.region?.name && (
                        <Typography
                            typeColor="black"
                            fontWeight="medium"
                            variant="span"
                            className={clsx(styles.cardInfoText, styles.city)}
                        >
                            <LocationIcon /> {currentSwipeSlide?.region?.name}
                        </Typography>
                    )}
                    {currentSwipeSlide?.purpose && (
                        <Tag
                            icon={
                                <div className={clsx(styles.tagIconNode, styles?.[`${currentSwipeSlide?.purpose}`])} />
                            }
                            className={clsx(styles.tagInfo, styles.isIcon)}
                        >
                            Романтические отношения
                        </Tag>
                    )}

                    {currentSwipeSlide?.about_me && (
                        <>
                            <div className={styles.label}>
                                <Typography
                                    uppercase
                                    variant="span"
                                    fontWeight="bold"
                                >
                                    Обо мне
                                </Typography>
                            </div>

                            <Typography
                                className={styles.text}
                                variant="p"
                            >
                                {currentSwipeSlide?.about_me}
                            </Typography>
                        </>
                    )}

                    <div className={styles.row}>
                        {/* Добавить город */}
                        {currentSwipeSlide?.gender && (
                            <div className={styles.rowItem}>
                                <div className={styles.label}>
                                    <Typography
                                        uppercase
                                        variant="span"
                                        fontWeight="bold"
                                    >
                                        Пол
                                    </Typography>
                                </div>

                                <Typography
                                    typeColor="gray"
                                    variant="p"
                                    className={styles.text}
                                >
                                    {Gender[currentSwipeSlide?.gender]}
                                </Typography>
                            </div>
                        )}
                    </div>
                    {currentSwipeSlide?.travel_plans && (
                        <>
                            <div className={styles.label}>
                                <Typography
                                    uppercase
                                    variant="span"
                                    fontWeight="bold"
                                >
                                    Мои тревел мечты
                                </Typography>
                            </div>

                            <Typography
                                className={styles.text}
                                variant="p"
                            >
                                {currentSwipeSlide?.travel_plans}
                            </Typography>
                        </>
                    )}
                </Container>
            </div>

            <div
                className={clsx(styles.arrowTop, isVisibleDetails && styles.visibleDetails)}
                onClick={hendleSrollDetails}
            >
                <ArrowTopIcon />
            </div>
        </div>
    );
};
