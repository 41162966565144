import { ApiClient } from '@/services/api.service';

interface SettingsDto {
    chat_url: string;
}

class SettingsApi {
    async getSettings() {
        const data = await ApiClient.get<SettingsDto>('/settings');
        return data;
    }
}

export const settingsApi = new SettingsApi();
