import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface UseSuccessfulMessageStore {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

export const useSuccessfulMessage = create<UseSuccessfulMessageStore>()(
    devtools(
        immer((set) => ({
            isOpen: false,
            setIsOpen: (isOpen: boolean) => set({ isOpen }),
        })),
    ),
);
