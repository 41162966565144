import { HeaderMenu, LikedList } from '@/components';
import { routesApp } from '@/constans/routes';

export const Liked = () => {
    return (
        <>
            <HeaderMenu
                backLink={routesApp.browse}
                title="Лайкнувшие"
            />
            <LikedList />
        </>
    );
};
