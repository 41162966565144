import React from 'react';
import Nouislider from 'nouislider-react';
import styles from './range-slider.module.scss';
interface Props {
    onChange?: (val: any) => void;
    minStart?: number;
    maxStart?: number;
}
export const RangeSlider: React.FC<Props> = ({ onChange, minStart = 24, maxStart = 24 }) => {
    return (
        <div className={styles.rangeContainer}>
            <Nouislider
                range={{ min: 18, max: 40 }}
                start={[minStart, maxStart]}
                connect
                className="range-slider"
                tooltips
                step={1}
                onChange={onChange}
                format={{
                    to: function (value: number) {
                        return Math.round(value);
                    },
                    from: function (value: number) {
                        return Math.round(value);
                    },
                }}
            />
        </div>
    );
};
