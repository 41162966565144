import { ApiClient } from '@/services/api.service';
import { Region, SearchParamsRegions } from './types';

class RegionApi {
    async getRegions(params: SearchParamsRegions): Promise<any> {
        const data = await ApiClient.get<Region[]>(`/regions/`, { params });
        return data;
    }
}

export const regionApi = new RegionApi();
