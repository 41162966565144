import { Typography, Uploader } from '@/ui-kit';
import styles from './upload-photo-registartion-form.module.scss';
import { Profile } from '@/types/profile';
import { usePhotoUpload } from '@/hooks/use-photo-upload';

import { ButtonSubmitStep } from '../button-submit-step';
import { useState } from 'react';
import { useDeletePhotoById } from '@/hooks/use-delete-photo-by-id';
import { useAuth } from '@/store/useAuth';
interface Props {
    userData?: Profile | null;
}
export const UploadPhotoRegistartionForm: React.FC<Props> = ({ userData }) => {
    const { mutateAsync: uploadPhoto } = usePhotoUpload();
    const { mutateAsync: deletePhotoById } = useDeletePhotoById();
    const setUserData = useAuth((state) => state.setProfile);

    const [photosCount, setPhotos] = useState<number>(userData?.photos?.length ?? 0);

    const handleUploadPhoto = async (file: File | null) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('photo[image]', file);
        const { data } = await uploadPhoto(formData);
        setUserData({ ...userData, photos: [...(userData?.photos ?? []), data] });
        // TODO поправить photosCount
        setPhotos(photosCount + 1);
    };

    const handleDeletePhoto = async (photoId?: number) => {
        if (!photoId) return;
        await deletePhotoById(photoId);
        setPhotos(photosCount - 1);
        setUserData({ ...userData, photos: userData?.photos?.filter((photo) => photo.id !== photoId) });
    };

    return (
        <form className={styles.form}>
            <Typography
                variant="p"
                fontWeight="medium"
                className={styles.titleForm}
                center
            >
                Загрузите фотографии
            </Typography>
            <div className={styles.formLabel}>
                <Typography
                    center
                    variant="p"
                    fontWeight="medium"
                >
                    Загрузите несколько своих фотографий, более 4 и ваш аккаунт будет выделяться в сравнении с
                    остальными
                </Typography>
            </div>
            <div className={styles.uploaderBlok}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <Uploader
                        key={index}
                        onChange={handleUploadPhoto}
                        photoUrl={userData?.photos[index]?.url}
                        onDeletePhoto={() => handleDeletePhoto(userData?.photos[index]?.id)}
                    />
                ))}
            </div>
            <ButtonSubmitStep
                stepNumber={2}
                isDisabled={!photosCount}
                href="/registration/step/3"
                isGray
            />
        </form>
    );
};
