import React from 'react';
import DatePickerComponent from 'react-datepicker';
import { IMaskInput } from 'react-imask';
import { DropdownIndicator } from '@/assets/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { ru } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import './data-picker.scss';
import { format } from 'date-fns';

export const DataPicker: React.FC<any> = ({ name, placeholder }) => {
    const { control } = useFormContext();
    return (
        <div className="datepicker">
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <DatePickerComponent
                            placeholderText={placeholder}
                            onChange={(date) => {
                                if (!date) return;
                                console.log(date);

                                onChange(format(date as Date, 'yyyy-MM-dd'));
                            }}
                            selected={value}
                            maxDate={new Date()}
                            locale={ru}
                            dateFormat="dd / MM / yyyy"
                            customInput={
                                <IMaskInput
                                    mask="00 / 00 / 0000"
                                    placeholder="17 / 08 / 1989"
                                    ref={ref}
                                />
                            }
                        />
                    );
                }}
            />
            <div className="react-datepicker__icon">
                <DropdownIndicator />
            </div>
        </div>
    );
};
