import { Container, Typography } from '@/ui-kit';
import { MessageChat } from '../message-chat';
import styles from './messages-list.module.scss';
import { Chat } from '@/types/chat';
import { SkeletonMessagesList } from './skeleton-messages-list';

interface Props {
    chatList: Chat[];
    isLoading: boolean;
}
export const MessagesList: React.FC<Props> = ({ chatList, isLoading }) => {
    return (
        <Container className={styles.container}>
            <div className={styles.label}>
                <Typography
                    typeColor="gray"
                    uppercase
                    variant="span"
                >
                    Чаты
                </Typography>
            </div>
            <div className={styles.messagesList}>
                {isLoading ? (
                    <SkeletonMessagesList />
                ) : chatList.length > 0 ? (
                    chatList.map((chat) => (
                        <MessageChat
                            chat={chat}
                            key={chat.id}
                        />
                    ))
                ) : (
                    <Typography
                        className={styles.noMessages}
                        typeColor="gray"
                    >
                        Нет чатов
                    </Typography>
                )}
            </div>
        </Container>
    );
};
