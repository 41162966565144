import { Button, Container, Typography } from '@/ui-kit';
import styles from './liked-list.module.scss';
import ImageMockSrc from '@/assets/images/mock/image-thumb.jpg';

export const LikedList: React.FC = () => {
    return (
        <Container className={styles.container}>
            <Typography
                className={styles.title}
                center
                fontWeight="medium"
            >
                Купите подписку на Proximity, чтобы узнать кто Вас лайкнул
            </Typography>
            <div className={styles.list}>
                {Array.from({ length: 10 }).map((_, index) => (
                    <div
                        className={styles.item}
                        key={index}
                    >
                        <div className={styles.image}>
                            <img
                                src={ImageMockSrc}
                                alt="avatar"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <Button
                typeColor="orange"
                variant="stretched"
                className={styles.button}
            >
                Узнать, кто тебя лайкнул
            </Button>
        </Container>
    );
};
