import { photoApi } from '@/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePhotoUpload = () => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: photoApi.uploadPhoto,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['photo-upload'] });
        },
        onError: (error) => {
            console.error('Error updating user data:', error);
        },
    });

    return mutation;
};
