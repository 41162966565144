import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
type Dir = 'left' | 'right' | null;
interface UseSwipedChangeStore {
    dir: Dir;
    isSwiping: boolean;
    setDir: (dir: Dir) => void;
    setIsSwiping: (isSwiping: boolean) => void;
}

export const useSwipedChange = create<UseSwipedChangeStore>()(
    devtools(
        immer((set) => ({
            dir: null,
            isSwiping: false,
            setDir: (dir: Dir) => set({ dir }),
            setIsSwiping: (isSwiping: boolean) => set({ isSwiping }),
        })),
    ),
);
