import { NavLink } from 'react-router-dom';
import styles from './menu-app.module.scss';
import { menuData } from './menu-data';
import { MarkerNotifyIcon } from '@/assets/icons';
interface Props {
    itemsMapCount?: {
        [key: string]: number;
    };
}
export const MenuApp: React.FC<Props> = ({ itemsMapCount }) => {
    return (
        <nav className={styles.menu}>
            <ul className={styles.list}>
                {menuData.map((item) => {
                    const IconComponent = item.icon;
                    return (
                        <li
                            key={item.title}
                            className={styles.item}
                        >
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? styles.link + ' ' + styles.active : styles.link
                                }
                                to={item.path}
                            >
                                <IconComponent />
                            </NavLink>
                            {itemsMapCount?.[item.value] && (
                                <div className={styles.count}>
                                    <MarkerNotifyIcon
                                        width={6}
                                        height={7}
                                    />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};
