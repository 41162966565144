import { useQuery } from '@tanstack/react-query';

import { settingsApi } from '@/api';

export const useGetSettings = () => {
    return useQuery({
        queryKey: ['settings'],
        queryFn: settingsApi.getSettings,
    });
};
