import { Button } from '@/ui-kit';
import { StepLabel } from '../step-label';
import styles from './button-submit-step.module.scss';
import clsx from 'clsx';
interface Props {
    isLoading?: boolean;
    stepNumber: number;
    href?: string;
    isDisabled?: boolean;
    isGray?: boolean;
}

export const ButtonSubmitStep: React.FC<Props> = ({ isLoading, href, isDisabled, isGray }) => {
    return (
        <div className={clsx(styles.wrapperButtonStep, isGray && styles.gray)}>
            <Button
                type="submit"
                variant="stretched"
                typeColor="orange"
                isLoading={isLoading}
                isDisabled={isDisabled}
                isLink={!!href}
                to={href}
            >
                Продолжить
            </Button>
        </div>
    );
};
