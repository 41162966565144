import { HeaderMenu, SettingsProfile } from '@/components';
import { routesApp } from '@/constans/routes';
import styles from './settings.module.scss';
export const Settings = () => {
    return (
        <div className={styles.settings}>
            <HeaderMenu
                backLink={routesApp.browse}
                title="Настройки"
                typeColor="gray"
                fixed
            />
            <SettingsProfile />
        </div>
    );
};
