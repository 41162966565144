import { Button, Container } from '@/ui-kit';
import { AvatarProfile, SettingsList } from './components';
import { useAuth } from '@/store/useAuth';
import { LogoutIcon } from '@/assets/icons';
import styles from './settings-profile.module.scss';
export const SettingsProfile: React.FC = () => {
    const userData = useAuth((state) => state.getUser());
    return (
        <Container className={styles.container}>
            <AvatarProfile
                name={userData?.public_name ?? ''}
                email={userData?.email ?? ''}
                avatar={userData?.photos?.[0]?.thumb_url?.toString()}
            />
            <SettingsList />
            <Button
                typeColor="orange"
                variant="stretched"
                className={styles.settingsButton}
            >
                <LogoutIcon />
                Выйти
            </Button>
        </Container>
    );
};
