import { profilesApi } from '@/api';
import { type VoteProfileDto } from '@/api/profiles';
import { useMutation } from '@tanstack/react-query';
interface MutationVoteProfileById {
    voteProfileDto: VoteProfileDto;
    profileId: number;
}
export const useVoteProfileById = () => {
    return useMutation({
        mutationFn: (mutationData: MutationVoteProfileById) =>
            profilesApi.voteProfileById(mutationData.profileId, mutationData.voteProfileDto),
        onSuccess: () => {
            console.log('User data updated successfully');
        },
        onError: (error) => {
            console.error('Error updating user data:', error);
        },
    });
};
