import React from 'react';
import styles from './new-couples.module.scss';

import { CouplesItem, SkeletonCouplesItem } from './components';
import { CouplesItemLike } from './components/couples-item-like';
import { couplesItems } from '@/mocks/couples-items';
import { Container, Typography } from '@/ui-kit';

interface Props {
    isLoading: boolean;
}
export const NewCouples: React.FC<Props> = ({ isLoading }) => {
    return (
        <>
            <Container>
                <div className={styles.label}>
                    <Typography
                        typeColor="gray"
                        uppercase
                        variant="span"
                    >
                        Новые пары
                    </Typography>
                </div>
            </Container>
            <div className={styles.list}>
                {isLoading ? (
                    Array.from({ length: 10 }).map((_, index) => <SkeletonCouplesItem key={index} />)
                ) : (
                    <>
                        <CouplesItemLike
                            avatar={couplesItems[couplesItems.length - 1].avatar}
                            count={couplesItems.length}
                        />
                        {couplesItems.map((item) => (
                            <CouplesItem
                                key={item.id}
                                ellipse={item.isViewed}
                                info={item.name}
                                avatar={item.avatar}
                            />
                        ))}
                    </>
                )}
            </div>
        </>
    );
};
