import { Outlet } from 'react-router';
import styles from './app-layout.module.scss';
import { MenuApp } from '@/components/menu-app';
import { SuccessfulRegistration } from '@/components/successful-registration';
import { useSuccessfulMessage } from '@/store/useSuccessfulMessage';
import { ScrollRestoration, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const mockCountItems = {
    messages: 3,
};
export const AppLayout = () => {
    const { isOpen, setIsOpen } = useSuccessfulMessage((state) => state);
    const location = useLocation();
    useEffect(() => {
        console.log('Location changed!', location.pathname);
    }, [location]);

    return (
        <>
            <ScrollRestoration />
            <div className={styles.appLayout}>
                <Outlet />
                <MenuApp itemsMapCount={mockCountItems} />
                {isOpen && <SuccessfulRegistration onClose={() => setIsOpen(false)} />}
            </div>
        </>
    );
};
