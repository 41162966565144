import { z } from 'zod';

export const registrationStepFourthSchema = z.object({
    min_age: z.number(),
    max_age: z.number(),
    purpose: z.enum(['love', 'fun', 'not_specified']).nullable(),
    looks_for: z.enum(['male', 'female', 'any'], { message: 'Пол обязателен' }),
});

export type RegistrationStepFourthType = z.infer<typeof registrationStepFourthSchema>;
