import clsx from 'clsx';
import styles from './navigation.module.scss';
import React, { useRef, useState } from 'react';
import { useSwipedChange } from '@/store/useSwipedChange';

interface Props {
    onChange: (idx: number, dir: 'left' | 'right') => void;
    currentIndex: number;
}
export const Navigation: React.FC<Props> = ({ onChange, currentIndex }) => {
    const { setIsSwiping, isSwiping } = useSwipedChange((state) => state);
    const [isMoved, setIsMoved] = useState(false);
    const handleTouch = () => setIsSwiping(true);
    const x = useRef<null | number>(null);
    const y = useRef<null | number>(null);

    const handleChangeLeftNavigate = () => {
        setIsSwiping(false);
        onChange(currentIndex, 'left');
    };
    const handleMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // console.log(isSwiping, 'isSwiping');

        if (!x.current || !y.current || !isMoved) {
            return;
        }
        const xIsEnd = event.clientX;
        const yIsEnd = event.clientY;

        const xisDiff = xIsEnd - x.current;
        const yisDiff = yIsEnd - y.current;

        if (xisDiff < 10 || yisDiff < 10) {
            setIsSwiping(true);
        } else {
            setIsSwiping(false);
        }
    };

    const handleChangeRightNavigate = () => {
        setIsSwiping(false);
        onChange(currentIndex, 'right');
    };
    return (
        <div
            onTouchMove={handleTouch}
            onMouseMove={handleMove}
            onTouchEnd={() => setIsSwiping(false)}
            onMouseDown={(e) => {
                setIsMoved(true);
                x.current = e.clientX;
                y.current = e.clientY;
            }}
            onMouseUp={() => {
                x.current = null;
                y.current = null;
                setIsMoved(false);
            }}
            className={clsx(styles.navigation, 'pressable')}
        >
            <div
                onTouchEnd={handleChangeLeftNavigate}
                onMouseUp={handleChangeLeftNavigate}
                className={clsx(styles.navigationItem, styles.navigationLeft)}
            >
                Left
            </div>
            <div
                onTouchEnd={handleChangeRightNavigate}
                onMouseUp={handleChangeRightNavigate}
                className={clsx(styles.navigationItem, styles.navigationRight)}
            >
                Right
            </div>
        </div>
    );
};
