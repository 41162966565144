import React from 'react';
import styles from './chat-message-item.module.scss';
import clsx from 'clsx';
import { Typography } from '@/ui-kit';
import { CheckMessageIcon, PonytailIcon } from '@/assets/icons';
interface Props {
    isMyMessage?: boolean;
    message?: string;
    status?: 'sent' | 'delivered' | 'read';
    time: string;
}
export const ChatMessageItem: React.FC<Props> = ({ isMyMessage, message, status, time }) => {
    return (
        <div className={clsx(isMyMessage ? styles.rowSent : styles.rowAccepted, styles.row)}>
            <div className={styles.message}>
                <Typography
                    typeColor="black"
                    variant="p"
                    className={styles.messageText}
                >
                    {isMyMessage && status === 'read' && (
                        <CheckMessageIcon
                            width={16}
                            height={16}
                            className={styles.messageIcon}
                        />
                    )}
                    {message}
                    <PonytailIcon className={styles.dekorIcon} />
                </Typography>
                {isMyMessage && (
                    <Typography
                        typeColor="gray"
                        className={styles.messageTime}
                        variant="span"
                    >
                        <b>Read</b> {time}
                    </Typography>
                )}
            </div>
        </div>
    );
};
