import { Typography } from '@/ui-kit';
import { CouplesItem } from '../couples-item';
import styles from './couples-item-like.module.scss';
import { LikeIcon } from '@/assets/icons';
interface Props {
    count?: number;
    avatar?: string;
}
export const CouplesItemLike: React.FC<Props> = ({ count, avatar }) => {
    return (
        <CouplesItem
            className={styles.wrapper}
            info="Лайки"
            avatar={avatar}
        >
            <div className={styles.box}>
                {count && (
                    <Typography
                        typeColor="white"
                        center
                        fontWeight="bold"
                        className={styles.count}
                    >
                        +{count}
                    </Typography>
                )}
                <div className={styles.icon}>
                    <LikeIcon
                        width={11}
                        height={12}
                    />
                </div>
            </div>
        </CouplesItem>
    );
};
