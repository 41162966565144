import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import clsx from 'clsx';
import styles from './couples-item.module.scss';

interface Props {
    className?: string;
    ellipse?: boolean;
}

export const SkeletonCouplesItem: React.FC<Props> = ({ className, ellipse }) => {
    return (
        <div className={clsx(className, styles.coupleItem)}>
            <div className={clsx(styles.avatar, ellipse && styles.ellipse)}>
                {/* Скелетон для аватара */}
                <Skeleton
                    circle={true}
                    height={60}
                    width={60}
                />
            </div>
            {/* Скелетон для текста информации */}
            <Skeleton
                width={60}
                height={10}
            />
        </div>
    );
};
