import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import styles from './tag.module.scss';
interface Props {
    className?: string;
    icon?: React.ReactNode;
}
export const Tag: React.FC<PropsWithChildren<Props>> = ({ className, children, icon }) => {
    // const classes = clsx(styles.tag, icon && styles.isIcon, className);
    return (
        <span className={clsx(styles.tag, icon && styles.isIcon, className)}>
            {icon}
            {children}
        </span>
    );
};
