import { useQuery } from '@tanstack/react-query';

import { regionApi } from '@/api';
import { SearchParamsRegions } from '@/api/region/types';

export const useGetRegions = (searchParams: SearchParamsRegions) => {
    return useQuery({
        queryKey: ['regions', searchParams.q],
        queryFn: () => regionApi.getRegions(searchParams),
        // enabled: !!searchParams.q,
    });
};
