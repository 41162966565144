import { HeartIcon, LikeIcon, MessageIcon, UserIcon } from '@/assets/icons';
import { routesApp } from '@/constans/routes';

interface MenuData {
    title: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    path: string;
    value: string;
}

export const menuData: MenuData[] = [
    {
        title: 'Лайки',
        icon: LikeIcon,
        path: routesApp.browse,
        value: 'likes',
    },
    {
        title: 'Лайкнувшие',
        icon: HeartIcon,
        path: routesApp.liked,
        value: 'liked',
    },
    {
        title: 'Сообщения',
        icon: MessageIcon,
        path: routesApp.messages,
        value: 'messages',
    },
    {
        title: 'Профиль',
        icon: UserIcon,
        path: routesApp.settings,
        value: 'settings',
    },
];
