import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './couples-item.module.scss';
import { Avatar, Typography } from '@/ui-kit';
interface Props {
    className?: string;
    ellipse?: boolean;
    info?: string;
    avatar?: string;
}
export const CouplesItem: React.FC<PropsWithChildren<Props>> = ({ className, ellipse, info, avatar, children }) => {
    return (
        <div className={clsx(className, styles.coupleItem)}>
            <div className={clsx(styles.avatar, ellipse && styles.ellipse)}>
                <Avatar
                    src={avatar}
                    alt="avatar"
                />
                {children}
            </div>
            {info && (
                <Typography
                    typeColor="brown"
                    className={styles.info}
                    fontWeight="bold"
                    center
                >
                    {info}
                </Typography>
            )}
        </div>
    );
};
