import { Typography } from '@/ui-kit';
import styles from './tool-item.module.scss';

interface Props {
    icon?: React.ReactNode;
    onClick?: () => void;
    title: string;
    text: string;
}
export const ToolItem: React.FC<Props> = ({ icon, onClick, title, text }) => {
    return (
        <div
            className={styles.toolItem}
            onClick={onClick}
        >
            <div className={styles.icon}>{icon}</div>
            <div className={styles.text}>
                <Typography
                    uppercase
                    className={styles.title}
                    fontWeight="semibold"
                >
                    {title}
                </Typography>
                <Typography
                    typeColor="gray"
                    className={styles.description}
                >
                    {text}
                </Typography>
            </div>
        </div>
    );
};
