import { SkeletonMessageChat } from '../message-chat';

export const SkeletonMessagesList: React.FC = () => {
    return (
        <div>
            {Array.from({ length: 10 }).map((_, index) => (
                <SkeletonMessageChat key={index} />
            ))}
        </div>
    );
};
