import { Avatar, Typography } from '@/ui-kit';
import styles from './user-info-chat.module.scss';

interface Props {
    status?: 'online' | 'offline';
}

export const UserInfoChat: React.FC<Props> = ({ status = 'online' }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatarWrap}>
                <Avatar
                    src="https://picsum.photos/200/300"
                    alt="avatar"
                    size={41}
                />
                {status === 'online' && <span className={styles.status}></span>}
            </div>
            <div className={styles.details}>
                <Typography
                    fontWeight="bold"
                    variant="span"
                    typeColor="brown"
                >
                    Светлана, 29
                </Typography>
                <Typography
                    fontWeight="bold"
                    variant="span"
                    typeColor="orange"
                >
                    {status}
                </Typography>
            </div>
        </div>
    );
};
