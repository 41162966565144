import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './chat-message-item.module.scss';
import clsx from 'clsx';
import { Typography } from '@/ui-kit';
import { CheckMessageIcon, PonytailIcon } from '@/assets/icons';

interface Props {
    isMyMessage?: boolean;
    status?: 'sent' | 'delivered' | 'read';
}

export const ChatMessageItemSkeleton: React.FC<Props> = ({ isMyMessage, status }) => {
    return (
        <div className={clsx(isMyMessage ? styles.rowSent : styles.rowAccepted, styles.row, styles.skeleton)}>
            <div className={styles.message}>
                <Typography
                    typeColor="black"
                    variant="p"
                    className={styles.messageText}
                >
                    {isMyMessage && status === 'read' && (
                        <CheckMessageIcon
                            width={16}
                            height={16}
                            className={styles.messageIcon}
                        />
                    )}
                    <Skeleton
                        width={'100%'}
                        height={20}
                    />{' '}
                    {/* Скелетон для текста сообщения */}
                    <PonytailIcon className={styles.dekorIcon} />
                </Typography>
                {isMyMessage && (
                    <Typography
                        typeColor="gray"
                        className={styles.messageTime}
                        variant="span"
                    >
                        <Skeleton width={50} /> {/* Скелетон для времени */}
                    </Typography>
                )}
            </div>
        </div>
    );
};
