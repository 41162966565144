import TextareaAutosize from 'react-textarea-autosize';
import { Button } from '@/ui-kit';
import styles from './form-message.module.scss';
import { TelegramIcon } from '@/assets/icons';
import React, { useState } from 'react';

interface Props {
    onSend: (data: any) => void;
}
export const FormMessage: React.FC<Props> = ({ onSend }) => {
    const [message, setMessage] = useState<string>('');
    const onEnterPress = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            onSend({
                payload: {
                    message,
                }, // Optional
            });
            setMessage('');
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        console.log(message);

        onSend({
            payload: {
                message,
            }, // Optional
        });

        setMessage('');
    };
    return (
        <form
            onSubmit={onSubmit}
            className={styles.formMessage}
        >
            <TextareaAutosize
                onKeyDown={onEnterPress}
                minRows={1}
                maxRows={4}
                className={styles.textarea}
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <Button
                type="submit"
                className={styles.button}
            >
                <TelegramIcon
                    width={24}
                    height={24}
                />
            </Button>
        </form>
    );
};
// {command: "message",…}
// command
// :
// "message"
// data
// :
// "{\"message\":\"test\"}"
// identifier
// :
// "{\"channel\":\"ChatChannel\",\"token\":\"zepHSq4svHcm-wt7q8xWOA\",\"profile\":\"1\"}"
// command
// :
// "message"
// data
// :
// "{\"messages\":\"123\",\"action\":\"ping\"}"
// identifier
// :
// "{\"channel\":\"ChatChannel\",\"token\":\"zepHSq4svHcm-wt7q8xWOA\",\"profile\":\"1\"}"
