import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './header-menu.module.scss';
import { ArrowBackUpIcon, VerticalDotsIcon } from '@/assets/icons';
import { Container, Typography } from '@/ui-kit';
import clsx from 'clsx';
interface Props {
    title?: string | React.ReactNode;
    backLink?: string;
    className?: string;
    fixed?: boolean;
    isGray?: boolean;
    typeColor?: 'white' | 'gray';
}

export const HeaderMenu: React.FC<PropsWithChildren<Props>> = ({
    title,
    backLink,
    className,
    fixed,
    children,
    isGray,
    typeColor = 'white',
}) => {
    return (
        <Container className={typeColor === 'gray' ? styles.headerGray : styles.headerWhite}>
            <nav className={clsx(styles.nav, fixed && styles.fixed, isGray && styles.gray, className)}>
                {backLink && (
                    <Link
                        className={styles.back}
                        to={backLink}
                    >
                        <ArrowBackUpIcon
                            width={18}
                            height={18}
                        />
                    </Link>
                )}
                {title && (
                    <Typography
                        variant="p"
                        fontWeight="medium"
                        className={styles.title}
                    >
                        {title}
                    </Typography>
                )}
                {children}
                {/* TODO add dropdown menu */}
                {/* <div className={styles['menu-dropdown']}>
                    <VerticalDotsIcon
                        width={24}
                        height={24}
                    />
                </div> */}
            </nav>
        </Container>
    );
};
