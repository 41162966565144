import { FunIcon, LoveIcon, NotSpecified } from '@/assets/icons';
interface DataGoal {
    value: 'love' | 'fun' | 'not_specified';
    illustarion: React.FC;
    text: string;
}
export const dataGoal: DataGoal[] = [
    { value: 'fun', illustarion: FunIcon, text: 'Новые друзья' },
    { value: 'love', illustarion: LoveIcon, text: 'Романтические отношения' },
    { value: 'not_specified', illustarion: NotSpecified, text: 'Как пойдет' },
];
