import { Avatar, Typography } from '@/ui-kit';
import styles from './avatar-profile.module.scss';
interface Props {
    avatar?: string;
    name?: string;
    email?: string;
}
export const AvatarProfile: React.FC<Props> = ({ avatar, name, email }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.avatarWrapper}>
                <Avatar
                    src={avatar}
                    size={79}
                />
                <span className={styles.status}></span>
            </div>
            <Typography
                className={styles.name}
                typeColor="brown"
                center
            >
                {name}
            </Typography>
            {email && (
                <a
                    className={styles.email}
                    href={`mailto:` + email}
                >
                    {email}
                </a>
            )}
        </div>
    );
};
