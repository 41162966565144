const TOKEN_KEY = 'token';

class JwtService {
    getToken() {
        return window.sessionStorage.getItem(TOKEN_KEY);
    }
    initToken() {
        const searchParams = new URLSearchParams(window.location.search);
        // const token = searchParams.get(TOKEN_KEY) || 'zepHSq4svHcm-wt7q8xWOA';
        const token = searchParams.get(TOKEN_KEY) || '';
        this.setToken(token);
    }

    setToken(token: string) {
        window.sessionStorage.setItem(TOKEN_KEY, token);
    }

    destroyToken() {
        window.sessionStorage.removeItem(TOKEN_KEY);
    }
}

export const jwtService = new JwtService();
